export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();
  const config = useRuntimeConfig();
  const base_api = config.public.apiUri;

  if (!userStore.token) {
    if (userStore.refreshToken) {
      var res = await userStore.apiRefresh({
        token: userStore.refreshToken,
      });
      if (res.error) {
        return await navigateTo("/admin/login");
      }
    } else {
      return await navigateTo("/admin/login");
    }
  } else {
    const { data, error } = await useFetch(`${base_api}/check_token`, {
      key: "checkToken",
      method: "get",
      headers: { Authorization: `Bearer ${userStore.token}` },
    });

    if (error.value) {
      userStore.setToken(null);
      userStore.setUser(null);
      userStore.setRefreshToken(null);
      return await navigateTo("/admin/login");
    }

    if (!userStore.user) {
      return await navigateTo("/admin/login");
    }

    if (userStore.user.role == "subagent") {
      const getPermission = async () => {
        const { data, error } = await useFetch(
          `${base_api}/agent/sub-agent/permission/${userStore.user._id}`,
          {
            key: "fetchData",
            headers: { Authorization: `Bearer ${userStore.token}` },
            method: "get",
          }
        );
        return data.value;
      };
      var permission = await getPermission();
      var arrayRoute = from.path.split("/");
      var routeAllow = ["feedback", "dashboard", "reward"];

      if (
        !routeAllow.includes(arrayRoute[2]) &&
        permission.filter((p) => p.permission_name == arrayRoute[2]).length == 0
      ) {
        return await navigateTo("/admin/feedback", { replace: true });
      }
    }
  }
});
